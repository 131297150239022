<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Custom.Custom") }}</div>
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Custom.Company") }}</div>
          <div class="search_selected">
            <el-input
              v-model="list.company"
              :placeholder="$t('Custom.CompanyName')"
              clearable
            />
          </div>
        </div>
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Login.Account") }}</div>
          <div class="search_selected">
            <el-input
              v-model="list.account"
              :placeholder="$t('Custom.Account')"
              clearable
            />
          </div>
        </div>
        <div class="machine_search_btn">
          <div
            class="search_btn"
            @click="searchMachine"
            :style="{ background: isBlue ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Machine.Search") }}
          </div>
        </div>
        <div class="machine_search_btn" style="margin-left: 5px">
          <div
            class="search_btn"
            @click="reset"
            :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Warning.Reset") }}
          </div>
        </div>
        <div class="machine_search_space"></div>
        <div class="machine_search_btn" @click="addCustom()">
          <div class="search_btn">{{ $t("Machine.Add") }}</div>
        </div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
          v-show="!noDataTable"
        >
          <el-table-column width="50" align="center" />
          <el-table-column
            min-width="20%"
            :label="$t('Custom.Company')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.company }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('UserInfo.UserName')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.nickname }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('Login.Account')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.account }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('Login.MobilePhone')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.mobile }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="20%"
            :label="$t('Custom.Email')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.email }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            min-width="20%"
            :label="$t('Setting.Level')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.role }}</div>
            </template>
          </el-table-column> -->
          <el-table-column
            min-width="20%"
            :label="$t('Setting.CreationTime')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.createtime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('AddMachine.Operation')"
            width="120"
            align="left"
          >
            <template slot-scope="scope">
              <img
                src="../../assets/image/bianji_main@2x.png"
                @click="handleEditOrder(scope.row)"
                class="OperationImg mgr"
              />
              <img
                src="../../assets/image/shanchu_mian@2x.png"
                @click="handleDelete(scope.$index, scope.row)"
                class="OperationImg mgr"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pagesize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div v-show="noDataTable" class="noData">
        <img src="../../assets/image/emptyData.png" />
        <div class="empty_text">{{ $t("Warning.NoData") }}</div>
      </div>
      <el-dialog
        :title="
          type == 'edit' ? $t('Custom.EditCompany') : $t('Custom.AddCompany')
        "
        :visible.sync="dialogVisible"
        width="600px"
        class="dialog"
      >
        <el-form
          ref="workShopForm"
          :model="customInfo"
          label-width="0px"
          autocomplete="off"
        >
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("Login.Account") }}
              </div>
              <el-input
                v-model.trim="customInfo.account"
                :placeholder="$t('Setting.IptAccout')"
                auto-complete="off"
                clearable
                @input="changeIpt($event)"
                :disabled="type === 'edit'"
              />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("Custom.Company") }}
              </div>
              <el-input
                v-model.trim="customInfo.name"
                :placeholder="$t('Custom.CompanyName')"
                auto-complete="off"
                clearable
                @input="changeIpt($event)"
              />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("UserInfo.UserName") }}
              </div>
              <el-input
                v-model.trim="customInfo.nickname"
                :placeholder="$t('Setting.IpUserName')"
                autocomplete="new-password"
                clearable
                @input="changeIpt($event)"
              />
            </el-form-item>
          </div>
          <div class="form-box" v-show="type == 'add'">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("Login.Password") }}
              </div>
              <el-input
                v-model.trim="customInfo.password"
                :placeholder="$t('Setting.IpPwd')"
                autocomplete="new-password"
                type="password"
                clearable
                @input="changeIpt($event)"
              />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("Login.MobilePhone") }}
              </div>
              <el-input
                v-model.trim="customInfo.phone"
                :placeholder="$t('Login.phoneNumber')"
                auto-complete="off"
                clearable
                @input="changeIpt($event)"
                type="number"
              />
            </el-form-item>
          </div>
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("Login.Email") }}
              </div>
              <el-input
                v-model.trim="customInfo.email"
                :placeholder="$t('Setting.IptEmail')"
                auto-complete="off"
                clearable
                @input="changeIpt($event)"
              />
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <div
            class="btn-normal pan-btn blue-btn"
            style="margin-right: 0px; background: #4875ef"
            @click="handleConfirm()"
            :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Login.OK") }}
          </div>
          <div
            class="btn-normal pan-btn tiffany-btn"
            @click="dialogVisible = false"
            style="background: #767e95"
          >
            {{ $t("AddMachine.Cancel") }}
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('Custom.AdPower')"
        :visible.sync="dialogVisibles"
        width="364px"
        class="dialog dialogDelete"
      >
        <!-- class="dialog" -->
        <el-form ref="workShopForm" label-width="0px">
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left">
                {{ $t("Custom.AdPwd") }}
              </div>
              <el-input
                v-model.trim="password"
                :placeholder="$t('Custom.pwd')"
                auto-complete="off"
                type="password"
                clearable
                @input="changeIpt($event)"
              />
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- 输入密码 加一个enter快捷键 -->
          <div
            class="btn-normal pan-btn blue-btn"
            style="margin-right: 0px; background: #4875ef"
            @click="handlePwd()"
            :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Login.OK") }}
          </div>
          <div
            class="btn-normal pan-btn tiffany-btn"
            @click="dialogVisibles = false"
            style="background: #767e95"
          >
            {{ $t("AddMachine.Cancel") }}
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import "../../style/table.less";
import {
  getCustomer,
  addCompany,
  editCompany,
  deleteCompany,
} from "@/api/custom";
export default {
  name: "Custom",
  data() {
    return {
      tableData: [],
      isBlue: false,
      isBlueSearch: false,
      isBlueConfire: false,
      type: "",
      noDataTable: false,
      isCleable: false,
      dialogVisible: false,
      customInfo: {
        name: "",
        account: "",
        password: "",
        phone: "",
        email: "",
        id: "",
        nickname: "",
      },

      list: {
        account: "",
        company: "",
      },
      repeat: true,
      repeatSearch: true,
      total: 0,
      page: 1,
      pagesize: 15,
      dialogVisibles: false,
      deleteForm: {
        password: null, //删除客户 输入的密码
        id: null, // 删除 id
      },
      password: null,
      deleteIndex: null, //删除传的索引
    };
  },
  created() {
    this.getCustom();
  },
  filters: {
    time(item) {
      const date = new Date(item * 1000);
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return (
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute
      );
    },
  },
  watch: {
    "$i18n.locale"(val) {
      this.changeRoleLan();
    },
  },
  methods: {
    changeRoleLan() {
      if (this.tableData.length > 0) {
        for (var i = 0; i < this.tableData.length; i++) {
          if (
            this.tableData[i].role === "超级管理员" ||
            this.tableData[i].role === "Super Administrator"
          ) {
            this.$set(
              this.tableData[i],
              "role",
              this.$t("UserInfo.SuperAdministrator")
            );
          }
        }
      }
    },
    checkZh(val) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(val)) {
        console.log("as");
        return false;
      } else {
        console.log("bs");
        return true;
      }
    },
    checkEmail(val) {
      const t =
        /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;
      if (!t.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    handleConfirm() {
      this.isBlueConfire = true;
      setTimeout(() => {
        this.isBlueConfire = false;
      }, 500);
      const params = {};
      if (!this.repeat) {
        return;
      }
      this.repeat = false;
      setTimeout(() => {
        this.repeat = true;
      }, 2000);
      if (this.type === "add" && !this.customInfo.account) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.IptAccout"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (
        this.type === "add" &&
        this.customInfo.account &&
        this.customInfo.account.length >= 20
      ) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.AccountExceed"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.checkZh(this.customInfo.account)) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.AccountCn"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.customInfo.name) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.CompanyName"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.customInfo.name.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.overCompany"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.customInfo.nickname) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.IpUserName"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.customInfo.nickname.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.UserExceed"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.type === "add" && !this.customInfo.password) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.IpPwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (
        this.type === "add" &&
        this.customInfo.password &&
        this.customInfo.password.length < 6
      ) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Digits"),
          type: "warning",
          duration: 1000,
        });
        return;
      }

      // if (!this.customInfo.phone) {
      //   this.$message({
      //     showClose: true,
      //     message: this.$t('Login.phoneNumber'),
      //     type: 'warning',
      //     duration: 1000
      //   })
      //   return
      // }
      if (!this.customInfo.email) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.IptEmail"),
          type: "warning",
          duration: 1000,
        });
        return;
      }

      if (!this.checkEmail(this.customInfo.email)) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.ErrEmail"),
          type: "warning",
          duration: 1000,
        });
        return;
      }

      if (this.type === "add") {
        params.password = this.$md5(this.customInfo.password);
        params.account = this.customInfo.account;
        params.mobile = this.customInfo.phone;
        params.email = this.customInfo.email;
        params.company = this.customInfo.name;
        params.nickname = this.customInfo.nickname;
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        addCompany(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.dialogVisible = false;
            this.$message({
              showClose: true,
              message: this.$t("Tip.AddSuccess"),
              type: "success",
              duration: 1000,
            });
            this.getCustom();
          }
        });
      } else {
        params.id = this.customInfo.id;
        params.nickname = this.customInfo.nickname;
        params.company = this.customInfo.name;
        params.mobile = this.customInfo.phone;
        params.email = this.customInfo.email;
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        editCompany(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.dialogVisible = false;
            this.$message({
              showClose: true,
              message: this.$t("Tip.EditSuccess"),
              type: "success",
              duration: 1000,
            });
            this.getCustom();
          }
        });
      }
    },
    resetDeleteForm() {
      const keys = ["id", "password"];
      // this.deleteForm[ke]
      keys.forEach((key) => {
        this.deleteForm[key] = "";
      });
      this.password = "";
    },
    // 删除
    handleDelete(idx, data) {
      // 每次都要清空输入密码表单
      this.resetDeleteForm();
      this.dialogVisibles = true;
      //  传id  调接口
      this.deleteIndex = idx;
      this.deleteForm.id = data?.id;
      console.log(idx, "data");
    },
    handlePwd() {
      // 删除前输入管理员密码
      // 点确定 调用删除接口
      // 未输入密码 提示输入密码
      if (!this.password) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.pwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.deleteForm.password = this.$md5(this.password);
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      deleteCompany(this.deleteForm, token, lang)
        .then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Setting.RemovedSuccessfully"),
              type: "success",
              duration: 1000,
            });
            this.resetDeleteForm();
            this.dialogVisibles = false;
            this.tableData.splice(this.deleteIndex, 1);
            // 刷新列表 或者直接在前台删除
            // this.getSerial("", "type", "delete");
          } else {
            this.dialogVisibles = false;
          }
        })
        .catch((err) => console.log(err));
    },
    clearData() {
      this.customInfo.name = "";
      this.customInfo.account = "";
      this.customInfo.password = "";
      this.customInfo.email = "";
      this.customInfo.phone = "";
      this.customInfo.id = "";
      this.customInfo.nickname = "";
    },
    changeIpt(e) {
      this.$forceUpdate();
    },
    addCustom() {
      this.clearData();
      this.dialogVisible = true;
      this.type = "add";
    },
    handleEditOrder(row) {
      this.dialogVisible = true;
      this.type = "edit";
      this.customInfo.name = row.company;
      this.customInfo.account = row.account;
      this.customInfo.email = row.email;
      this.customInfo.phone = row.mobile;
      this.customInfo.id = row.id;
      this.customInfo.nickname = row.nickname;
    },
    reset() {
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      this.list.account = "";
      this.list.company = "";
      this.getCustom();
    },
    searchMachine() {
      if (!this.repeatSearch) {
        return;
      }
      this.repeatSearch = false;
      setTimeout(() => {
        this.repeatSearch = true;
      }, 2000);
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      if (!this.list.account && !this.list.company) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Criteria"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.getCustom();
    },
    async getCustom() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      const account = this.list.account;
      const company = this.list.company;
      params.device_name = this.list.device_name;
      params.account = account;
      params.company = company;
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("language");
      getCustomer(params)
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.count;
          this.noDataTable = this.total === 0;
          this.changeRoleLan();
        })
        .finally(() => {
          loading.close();
        });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getCustom();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCustom();
    },
  },
};
</script>

<style lang="less" scoped>
@media (max-width: 1367px) {
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }

  .dialogs /deep/ .el-dialog {
    margin-top: 12vh !important;
  }
}

@media (min-width: 1365px) and (max-width: 1441px) {
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}

.pagination-container {
  margin: 20px 25px;
  float: left;
}

.mgr {
  margin-right: 0px !important;
  margin-left: 10px;
}

.dialog /deep/ input::-webkit-outer-spin-button,
.dialog /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
}

.dialog /deep/ .el-dialog__body {
  padding: 0px 30px 10px 30px;
}

.dialog /deep/ input[type="number"] {
  -moz-appearance: textfield;
}

.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}

.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}

.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}

.dialog /deep/ .el-input__suffix {
  right: 20px;
}
.dialogDelete /deep/ .el-dialog {
  margin-top: 30vh !important;
}
.dialog /deep/ .el-input__suffix {
  top: 2px;
}

.dialog .userRole /deep/ .el-input__suffix {
  right: 20px !important;
}

.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}

.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

.dialog /deep/ .el-select__tags {
  height: auto !important;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog /deep/ .el-select {
  width: 100%;
}

.dialog /deep/ .el-select .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}

.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}

.dialog /deep/ .el-input__icon {
  line-height: 35px;
}

.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}

.dialog /deep/ .el-dialog__body {
  padding: 20px 20px !important;
}

.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}

.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}

.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  position: absolute;
  flex-direction: column;
  left: 0;
  top: 200px;

  .empty_text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
  }
}

.pagination-container {
  margin: 20px 25px;
  float: left;
}

.app-wrapper /deep/ .el-table__empty-text {
  line-height: 20px !important;
  font-weight: bold;
  font-size: 16px;
  color: #909399;
}

.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;

    .table_content {
      height: calc(100vh - 200px);
      overflow: auto;

      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        background-color: #ebecee;
        overflow-x: hidden;
      }

      /deep/ .el-table tr {
        background-color: #ebecee !important;
      }
    }

    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }

    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;

      .machine_search_list {
        margin-right: 20px;

        // flex: 2.5;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }

        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }

          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }

          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }

          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }

          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }

          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }

      .machine_search_btn {
        flex: 0.55;

        .search_btn {
          width: 70px;
          height: 32px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 32px;
          border-radius: 30px;
          margin: 10px 0px;
          cursor: pointer;
        }

        .edi_color {
          background-color: #767e95;
        }
      }

      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
